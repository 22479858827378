import React from 'react';

import useWindowDimensions from 'app/shared/hooks/useWindowDimensions';

import Logo from './../shared/images/logo.png';

import './Footer.scss';

function Footer() {
	const {width} = useWindowDimensions();
	const requiredHeight = width > 792 ? '120px' : '100px';

	const logoStyle = {
		marginTop: '10px',
		height: requiredHeight,
	};

	const year = new Date().getFullYear();

	return (
		<div className='footer'>
			<div className='footer-area'>
				<img src={Logo} alt='Logo EzN3 in footer' style={logoStyle} />
				<p className='footer-text'>
					&copy; {year} EzN3. All rights reserved
				</p>
			</div>
		</div>
	);
}

export default Footer;

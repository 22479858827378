import React, {createContext, useState} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

const LanguageContext = createContext();

function LanguageContextProvider(props) {
	const [language, setLanguage] = useState('nl');

	function changeLanguage(lang = 'nl') {
		setLanguage(lang);
		i18next.changeLanguage(lang);
	}

	const toDutch = () => setLanguage('nl');

	const toEnglish = () => setLanguage('en');

	console.log(language);

	return (
		<LanguageContext.Provider
			value={{language, changeLanguage, toDutch, toEnglish}}>
			{props.children}
		</LanguageContext.Provider>
	);
}

LanguageContextProvider.propTypes = {
	children: PropTypes.any,
};

export {LanguageContext, LanguageContextProvider};

import React, {useContext} from 'react';

import {Button} from '@material-ui/core';

import {LanguageContext} from 'app/shared/context/languageContext';

import './LanguageSwitcher.scss';

function LanguageSwitcher() {
	const {changeLanguage, language} = useContext(LanguageContext);

	const activeClassName = 'active-lang';

	return (
		<div className='language-switcher'>
			<Button
				onClick={() => changeLanguage('nl')}
				className={`${
					language === 'nl' ? activeClassName : ''
				} button`}>
				NL
			</Button>
			<Button
				onClick={() => changeLanguage('en')}
				className={`${
					language === 'en' ? activeClassName : ''
				} button`}>
				EN
			</Button>
		</div>
	);
}

export default LanguageSwitcher;

import React from 'react';

import {Link} from 'react-router-dom';
import useWindowDimensions from 'app/shared/hooks/useWindowDimensions';

import logo from 'app/shared/images/logo_ezn3.png';

import './Header.scss';

function Logo() {
	const {width} = useWindowDimensions();
	const requiredHeight = width > 700 ? '60px' : '50px';

	return (
		<div
			style={{
				float: 'left',
				marginLeft: '20px',
				marginRight: '20px',
				height: requiredHeight,
			}}>
			<Link to='/home'>
				<img
					style={{
						paddingTop: '10px',
						paddingBottom: '10px',
						height: requiredHeight,
					}}
					src={logo}
					alt='Logo EzN3 in header'
				/>
			</Link>
		</div>
	);
}

export default Logo;

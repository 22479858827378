import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';

import './styles/globalStyles.scss';

import * as serviceWorker from './serviceWorker';

import App from './app/App';

import i18n from 'i18n';
import {I18nextProvider} from 'react-i18next';
import {LanguageContextProvider} from 'app/shared/context/languageContext';

ReactDOM.render(
	<LanguageContextProvider>
		<I18nextProvider i18n={i18n}>
			<Router>
				<App />
			</Router>
		</I18nextProvider>
	</LanguageContextProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';

import './App.scss';

//import Header from './header/Header';
import MainContent from './MainContent';
import Footer from './footer/Footer';

import logo from './shared/images/logo.png';

function App() {
	return (
		<div className='app'>
			<div className='container'>
				{/*<Header />*/}
				<MainContent />
				<Footer />
			</div>

			<div className='image'></div>
			<div className='fading-background'></div>
			<div className='logo'>
				<img src={logo} alt='Logo EzN3' />
			</div>
		</div>
	);
}

export default App;

// Value in input/textarea must have the state content = the one and only truth

import React from 'react';

import {Redirect, Route, Switch} from 'react-router-dom';

import './MainContent.scss';

import Landing from './landing/Landing';
//import Home from './home/Home';
//import Projects from './projects/Projects';

function MainContent() {
	return (
		<div className='mainContent'>
			<Switch>
				{/*				<Route exact path='/home'>
					<Home />
				</Route>
				<Route exact path='/projects'>
					<Projects />
				</Route>
				<Route exact path='/contact'>
					<h1>Contact</h1>
				</Route>*/}
				<Route exact path='/landing'>
					<Landing />
				</Route>
				<Route exact path='/'>
					<Redirect to='/landing' />
				</Route>
			</Switch>
		</div>
	);
}

export default MainContent;

import React from 'react';

import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import LanguageSwitcher from '../header/language-switcher/LanguageSwitcher';
import Logo from '../header/Logo';

import working_jonas from '../shared/images/working_jonas.jpg';

import './Landing.scss';

function Landing() {
	const {t} = useTranslation();

	return (
		<div className='landing'>
			<Logo className='logo' />
			<LanguageSwitcher className='language-switcher' />
			<div className='section'>
				<h1 className='hello-title'>{t('landing-page.hello')}</h1>
				<div className='image-jonas'>
					<img
						src={working_jonas}
						alt='Jonas at work'
						className='img-style'
					/>
				</div>
				<p className='explanation-text'>
					{t('landing-page.explanation')}
				</p>
				<p className='media-text'>{t('landing-page.linkedIn')}</p>
				<Link
					className='button-style'
					to={{
						pathname: 'https://www.linkedin.com/in/baert-jonas/',
					}}
					target='_blank'>
					{t('landing-page.to_linkedIn')}
				</Link>
				<p className='media-text'>{t('landing-page.facebook')}</p>
				<Link
					className='button-style'
					to={{
						pathname: 'https://www.facebook.com/BoekenJonasBaert/',
					}}
					target='_blank'>
					{t('landing-page.book')}
				</Link>
			</div>
		</div>
	);
}

export default Landing;
